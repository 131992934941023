import { useTranslation } from "next-i18next";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import Logo from "../logo/Logo";
import Link from "next/link";

const Sidebar = ({ showMobilemenu }) => {
  const { data: session } = useSession();
  const { t } = useTranslation("common");
  let router = useRouter();
  const location = router.pathname;

  return (
    <>
      <div className="sidenav-header">
        <i
          className="fas fa-times fa-2x p-3 cursor-pointer text-secondary opacity-7 position-absolute end-0 top-0 d-xl-none"
          aria-hidden="true"
          id="iconSidenav"
          onClick={showMobilemenu}
        />
        <Logo />
      </div>
      <hr className="horizontal dark mt-0" />
      <div
        className="collapse navbar-collapse w-auto h-auto"
        id="sidenav-collapse-main"
      >
        <ul className="navbar-nav">
          {/* dashboard */}
          <li className="nav-item">
            <Link
              href="/dashboard"
              className={
                "nav-link text-wrap " +
                (location === "/dashboard" || location.includes("/dashboard")
                  ? "active nav-link py-3"
                  : "nav-link py-3")
              }
              style={
                location === "/dashboard" || location.includes("/dashboard")
                  ? {
                      color: "var(--bs-bota-light-blue)",
                    }
                  : {
                      color: "var(--bs-dark)",
                    }
              }
              passHref
              onClick={showMobilemenu}
            >
              <i
                className={`fas fa-gauge`}
                style={
                  location === "/dashboard" || location.includes("/dashboard")
                    ? {
                        color: "var(--bs-bota-strong-cyan-lime-green)",
                      }
                    : { color: "var(--bs-secondary)" }
                }
              />
              <span className="ms-3 d-inline-block">
                {t("common:menu.dashboard")}
              </span>
            </Link>
          </li>

          {/* TODO: Restrict to waba role only */}
          {(session?.user?.email === "waba@dentricedev.com" ||
            session?.user?.role === "admin") && (
            <li className="nav-item">
              <a
                data-bs-toggle="collapse"
                href="#collapseWabaMgmt"
                role="button"
                aria-controls="collapseWabaMgmt"
                className={
                  "nav-link text-wrap " + location == "/whatsapp" ||
                  (location.includes("/whatsapp/templates") ||
                  location.includes("/whatsapp/embedded-signup") ||
                  location.includes("/whatsapp/chat")
                    ? "active nav-link"
                    : "nav-link text-wrap")
                }
                style={
                  location === "/whatsapp" ||
                  location.includes("/whatsapp/templates") ||
                  location.includes("/whatsapp/embedded-signup") ||
                  location.includes("/whatsapp/chat")
                    ? {
                        color: "var(--bs-bota-strong-cyan-lime-green)",
                      }
                    : { color: "var(--bs-secondary)" }
                }
                aria-expanded={
                  location === "/whatsapp" ||
                  location.includes("/whatsapp/templates") ||
                  location.includes("/whatsapp/embedded-signup") ||
                  location.includes("/whatsapp/chat")
                    ? "true"
                    : "false"
                }
              >
                <i className="fab fa-whatsapp" />
                <span className="ms-3 d-inline-block">
                  {t("common:menu.whatsapp")}
                </span>
              </a>

              <div
                className={`collapse ${
                  location === "/whatsapp" ||
                  location.includes("/whatsapp/templates") ||
                  location.includes("/whatsapp/embedded-signup") ||
                  location.includes("/whatsapp/chat")
                    ? "show"
                    : ""
                }`}
                id="collapseWabaMgmt"
              >
                <ul className="nav ps-3">
                  {/* whatsapp accounts */}
                  <li className="nav-item">
                    <Link
                      href="/whatsapp"
                      className={
                        "nav-link text-wrap " +
                        (location === "/whatsapp"
                          ? "active nav-link py-3"
                          : "nav-link py-3")
                      }
                      style={
                        location === "/whatsapp"
                          ? {
                              color: "var(--bs-bota-light-blue)",
                            }
                          : {
                              color: "var(--bs-dark)",
                            }
                      }
                      passHref
                      onClick={showMobilemenu}
                    >
                      <i
                        className={`fab fa-whatsapp `}
                        style={
                          location === "/whatsapp"
                            ? {
                                color: "var(--bs-bota-strong-cyan-lime-green)",
                              }
                            : { color: "var(--bs-secondary)" }
                        }
                      />
                      <span className="ms-3 d-inline-block">
                        {t("common:menu.waba-accounts")}
                      </span>
                    </Link>
                  </li>

                  {/* message templates */}
                  <li className="nav-item">
                    <Link
                      href="/whatsapp/templates"
                      className={
                        "nav-link text-wrap " +
                        (location.includes("/whatsapp/templates")
                          ? "active nav-link py-3"
                          : "nav-link py-3")
                      }
                      style={
                        location.includes("/whatsapp/templates")
                          ? {
                              color: "var(--bs-bota-light-blue)",
                            }
                          : {
                              color: "var(--bs-dark)",
                            }
                      }
                      passHref
                      onClick={showMobilemenu}
                    >
                      <i
                        className={`far fa-message`}
                        style={
                          location.includes("/whatsapp/templates")
                            ? {
                                color: "var(--bs-bota-strong-cyan-lime-green)",
                              }
                            : { color: "var(--bs-secondary)" }
                        }
                      />
                      <span className="ms-3 d-inline-block">
                        {t("common:menu.message-templates")}
                      </span>
                    </Link>
                  </li>

                  {/* inbox chat */}
                  <li className="nav-item">
                    <Link
                      href="/whatsapp/chat"
                      className={
                        "nav-link text-wrap " +
                        (location.includes("/whatsapp/chat")
                          ? "active nav-link py-3"
                          : "nav-link py-3")
                      }
                      style={
                        location.includes("/whatsapp/chat")
                          ? {
                              color: "var(--bs-bota-light-blue)",
                            }
                          : {
                              color: "var(--bs-dark)",
                            }
                      }
                      passHref
                      onClick={showMobilemenu}
                    >
                      <i
                        className={`far fa-comments`}
                        style={
                          location.includes("/whatsapp/chat")
                            ? {
                                color: "var(--bs-bota-strong-cyan-lime-green)",
                              }
                            : { color: "var(--bs-secondary)" }
                        }
                      />
                      <span className="ms-3 d-inline-block">
                        {t("common:menu.whatsapp-chat")}
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          )}

          {/* companies menu */}
          <li className="nav-item">
            <Link
              href="/companies"
              className={
                "nav-link text-wrap " +
                (location.includes("/companies")
                  ? "active nav-link py-3"
                  : "nav-link py-3")
              }
              style={
                location.includes("/companies")
                  ? {
                      color: "var(--bs-bota-light-blue)",
                    }
                  : {
                      color: "var(--bs-dark)",
                    }
              }
              passHref
              onClick={showMobilemenu}
            >
              <i
                className={`fas fa-building`}
                style={
                  location.includes("/companies")
                    ? {
                        color: "var(--bs-bota-strong-cyan-lime-green)",
                      }
                    : { color: "var(--bs-secondary)" }
                }
              />
              <span className="ms-3 d-inline-block">
                {t("common:menu.companies")}
              </span>
            </Link>
          </li>

          {/* all-companies menu */}
          {session?.user?.role === "admin" && (
            <li className="nav-item">
              <Link
                href="/all-companies"
                className={
                  "nav-link text-wrap " +
                  (location.includes("/all-companies")
                    ? "active nav-link py-3"
                    : "nav-link py-3")
                }
                style={
                  location.includes("/all-companies")
                    ? {
                        color: "var(--bs-bota-light-blue)",
                      }
                    : {
                        color: "var(--bs-dark)",
                      }
                }
                passHref
                onClick={showMobilemenu}
              >
                <i
                  className={`fas fa-building`}
                  style={
                    location.includes("/all-companies")
                      ? {
                          color: "var(--bs-bota-strong-cyan-lime-green)",
                        }
                      : { color: "var(--bs-secondary)" }
                  }
                />
                <span className="ms-3 d-inline-block">
                  {t("common:menu.all-companies")}
                </span>
              </Link>
            </li>
          )}

          {/* companies subscriptions menu */}
          {/* {session?.user?.currentCompany &&
            session?.user?.currentCompany?.owner && (
              <li className="nav-item">
                <Link
                  href="/subscriptions"
                  className={
                    "nav-link text-wrap " +
                    (location.includes("/subscriptions")
                      ? "active nav-link py-3"
                      : "nav-link py-3")
                  }
                   style={
                location.includes("/subscriptions")
                  ? {
                      color: "var(--bs-bota-light-blue)",
                    }
                  : {
                      color: "var(--bs-dark)",
                    }
              }
                  passHref
                  onClick={showMobilemenu}
                >
                  <i
                    className={`fas fa-bag-shopping`}
                    style={
                  location.includes("/subscriptions")
                    ? {
                        color: "var(--bs-bota-strong-cyan-lime-green)",
                      }
                   : { color: "var(--bs-secondary)" }
                }
                   />
                  <span className="ms-3 d-inline-block">
                    {t("common:menu.subscriptions")}
                  </span>
                </Link>
              </li>
            )} */}

          {session?.user?.role === "admin" && (
            <>
              {/* subscription and invoices */}
              <li className="nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#collapseSubscriptionsMgmt"
                  role="button"
                  aria-controls="collapseSubscriptionsMgmt"
                  className={
                    "nav-link text-wrap " +
                    (location.includes("/admin/invoices") ||
                    location.includes("/admin/subscription-plans")
                      ? "active nav-link"
                      : "nav-link text-wrap")
                  }
                  style={
                    location.includes("/admin/invoices") ||
                    location.includes("/admin/subscription-plans")
                      ? {
                          color: "var(--bs-bota-strong-cyan-lime-green)",
                        }
                      : { color: "var(--bs-secondary)" }
                  }
                  aria-expanded={
                    location.includes("/admin/invoices") ||
                    location.includes("/admin/subscription-plans")
                      ? "true"
                      : "false"
                  }
                >
                  <i className="fas fa-cash-register" />
                  <span className="ms-3 d-inline-block">
                    {t("common:menu.subscriptions")}
                  </span>
                </a>

                <div
                  className={`collapse ${
                    location.includes("/admin/invoices") ||
                    location.includes("/admin/subscription-plans")
                      ? "show"
                      : ""
                  }`}
                  id="collapseSubscriptionsMgmt"
                >
                  <ul className="nav ps-3">
                    {/* subscription plans */}
                    <li className="nav-item">
                      <Link
                        href="/admin/subscription-plans"
                        className={
                          "nav-link text-wrap " +
                          (location.includes("/admin/subscription-plans")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location.includes("/admin/subscription-plans")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-bell `}
                          style={
                            location.includes("/admin/subscription-plans")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.subscription-plans")}
                        </span>
                      </Link>
                    </li>

                    {/* customer invoices */}
                    <li className="nav-item">
                      <Link
                        href="/admin/invoices"
                        className={
                          "nav-link text-wrap " +
                          (location.includes("/admin/invoices")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location.includes("/admin/invoices")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-receipt`}
                          style={
                            location.includes("/admin/invoices")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.customer-invoices")}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* user management */}
              <li className="nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#collapseUserManagement"
                  role="button"
                  aria-controls="collapseUserManagement"
                  className={
                    "nav-link text-wrap " +
                    (location.includes("/admin/company-permissions") ||
                    location.includes("/admin/permissions") ||
                    location.includes("/admin/roles") ||
                    location.includes("/admin/users/")
                      ? "active nav-link"
                      : "nav-link text-wrap")
                  }
                  style={
                    location.includes("/admin/company-permissions") ||
                    location.includes("/admin/permissions") ||
                    location.includes("/admin/roles") ||
                    location.includes("/admin/users/")
                      ? {
                          color: "var(--bs-bota-strong-cyan-lime-green)",
                        }
                      : { color: "var(--bs-secondary)" }
                  }
                  aria-expanded={
                    location.includes("/admin/company-permissions") ||
                    location.includes("/admin/permissions") ||
                    location.includes("/admin/roles") ||
                    location.includes("/admin/users/")
                      ? "true"
                      : "false"
                  }
                >
                  <i className="fas fa-users-gear" />
                  <span className="ms-3 d-inline-block">
                    {t("common:menu.user-management")}
                  </span>
                </a>

                <div
                  className={`collapse ${
                    location.includes("/admin/company-permissions") ||
                    location.includes("/admin/permissions") ||
                    location.includes("/admin/roles") ||
                    location.includes("/admin/users/")
                      ? "show"
                      : ""
                  }`}
                  id="collapseUserManagement"
                >
                  <ul className="nav ps-3">
                    <li className="nav-item">
                      <Link
                        href="/admin/roles"
                        className={
                          "nav-link text-wrap " +
                          (location === "/admin/roles" ||
                          location.includes("/admin/roles")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/admin/roles" ||
                          location.includes("/admin/roles")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-user-shield`}
                          style={
                            location === "/admin/roles" ||
                            location.includes("/admin/roles")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.roles")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/admin/permissions"
                        className={
                          "nav-link text-wrap " +
                          (location === "/admin/permissions" ||
                          location.includes("/admin/permissions")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/admin/permissions" ||
                          location.includes("/admin/permissions")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-person-circle-check`}
                          style={
                            location === "/admin/permissions" ||
                            location.includes("/admin/permissions")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.permissions")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/admin/company-permissions"
                        className={
                          "nav-link text-wrap " +
                          (location === "/admin/company-permissions" ||
                          location.includes("/admin/company-permissions")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/admin/company-permissions" ||
                          location.includes("/admin/company-permissions")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-person-circle-check`}
                          style={
                            location === "/admin/company-permissions" ||
                            location.includes("/admin/company-permissions")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.company-permissions")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/admin/users/customers"
                        className={
                          "nav-link text-wrap " +
                          (location === "/admin/users/customers" ||
                          location.includes("/admin/users/customers")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/admin/users/customers" ||
                          location.includes("/admin/users/customers")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-people-carry-box`}
                          style={
                            location === "/admin/users/customers" ||
                            location.includes("/admin/users/customers")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.customers")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/admin/users/customer-support"
                        className={
                          "nav-link text-wrap " +
                          (location === "/admin/users/customer-support" ||
                          location.includes("/admin/users/customer-support")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/admin/users/customer-support" ||
                          location.includes("/admin/users/customer-support")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-people-pulling`}
                          style={
                            location === "/admin/users/customer-support" ||
                            location.includes("/admin/users/customer-support")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.customer-support")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/admin/users/admins"
                        className={
                          "nav-link text-wrap " +
                          (location === "/admin/users/admins" ||
                          location.includes("/admin/users/admins")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/admin/users/admins" ||
                          location.includes("/admin/users/admins")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-user-shield`}
                          style={
                            location === "/admin/users/admins" ||
                            location.includes("/admin/users/admins")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.admins")}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </>
          )}

          {session?.user?.currentCompany && (
            <>
              {/* integrations  */}
              {session?.user?.currentCompany.access_blocks.includes(
                "integrations"
              ) && (
                <li className="nav-item">
                  <a
                    data-bs-toggle="collapse"
                    href="#integrations"
                    role="button"
                    aria-controls="integrations"
                    className={
                      "nav-link text-wrap " +
                      (location === "/integrations/whatsapp"
                        ? "active nav-link"
                        : "nav-link text-wrap")
                    }
                    style={
                      location === "/integrations/whatsapp"
                        ? {
                            color: "var(--bs-bota-strong-cyan-lime-green)",
                          }
                        : { color: "var(--bs-secondary)" }
                    }
                    aria-expanded={
                      location === "/integrations/whatsapp" ? "true" : "false"
                    }
                  >
                    <i className="fas fa-shapes" />
                    <span className="ms-3 d-inline-block">
                      {t("common:menu.integrations")}
                    </span>
                  </a>

                  <div
                    className={`collapse ${
                      location === "/integrations/whatsapp" ? "show" : ""
                    }`}
                    id="integrations"
                  >
                    <ul className="nav ps-3">
                      <li className="nav-item">
                        <Link
                          href="/integrations/whatsapp"
                          className={
                            "nav-link text-wrap " +
                            (location === "/integrations/whatsapp"
                              ? "active nav-link py-3"
                              : "nav-link py-3")
                          }
                          style={
                            location === "/integrations/whatsapp"
                              ? {
                                  color: "var(--bs-bota-light-blue)",
                                }
                              : {
                                  color: "var(--bs-dark)",
                                }
                          }
                          passHref
                          onClick={showMobilemenu}
                        >
                          <i
                            className={`fab fa-whatsapp`}
                            style={
                              location === "/integrations/whatsapp"
                                ? {
                                    color:
                                      "var(--bs-bota-strong-cyan-lime-green)",
                                  }
                                : { color: "var(--bs-secondary)" }
                            }
                          />
                          <span className="ms-3 d-inline-block">
                            {t("common:menu.whatsapp")}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              )}

              {/* whatsapp chat */}
              {session?.user?.currentCompany.access_blocks.includes(
                "whatsapp_chat"
              ) && (
                <li className="nav-item">
                  <Link
                    href="/integrations/whatsapp/chat"
                    className={
                      "nav-link text-wrap " +
                      (location === "/integrations/whatsapp/chat" ||
                      location.includes("/integrations/whatsapp/chat")
                        ? "active nav-link py-3"
                        : "nav-link py-3")
                    }
                    style={
                      location === "/integrations/whatsapp/chat" ||
                      location.includes("/integrations/whatsapp/chat")
                        ? {
                            color: "var(--bs-bota-light-blue)",
                          }
                        : {
                            color: "var(--bs-dark)",
                          }
                    }
                    passHref
                    onClick={showMobilemenu}
                  >
                    <i
                      className={`far fa-comments`}
                      style={
                        location === "/integrations/whatsapp/chat" ||
                        location.includes("/integrations/whatsapp/chat")
                          ? {
                              color: "var(--bs-bota-strong-cyan-lime-green)",
                            }
                          : { color: "var(--bs-secondary)" }
                      }
                    />
                    <span className="ms-3 d-inline-block">
                      {t("common:menu.whatsapp-chat")}
                    </span>
                  </Link>
                </li>
              )}

              {/* contacts */}
              <li className="nav-item">
                <Link
                  href="/company/contacts"
                  className={
                    "nav-link text-wrap " +
                    (location === "/company/contacts" ||
                    location.includes("/company/contacts")
                      ? "active nav-link py-3"
                      : "nav-link py-3")
                  }
                  style={
                    location === "/company/contacts" ||
                    location.includes("/company/contacts")
                      ? {
                          color: "var(--bs-bota-light-blue)",
                        }
                      : {
                          color: "var(--bs-dark)",
                        }
                  }
                  passHref
                  onClick={showMobilemenu}
                >
                  <i
                    className={`fas fa-address-book`}
                    style={
                      location === "/company/contacts" ||
                      location.includes("/company/contacts")
                        ? {
                            color: "var(--bs-bota-strong-cyan-lime-green)",
                          }
                        : { color: "var(--bs-secondary)" }
                    }
                  />
                  <span className="ms-3 d-inline-block">
                    {t("common:menu.contacts")}
                  </span>
                </Link>
              </li>

              {/* smart agent module */}
              {(session.user?.currentCompany?.access_blocks?.includes(
                "smart_agent"
              ) ||
                session?.user?.role === "admin") && (
                <li className="nav-item">
                  <a
                    data-bs-toggle="collapse"
                    href="#collapseSmartReply"
                    role="button"
                    aria-controls="collapseSmartReply"
                    className={
                      "nav-link text-wrap " +
                      (location.includes("/smart-agent/agents") ||
                      location.includes("/smart-agent/all-agents") ||
                      location.includes("/smart-agent/auto-reply") ||
                      location.includes("/smart-agent/tools") ||
                      location.includes("/smart-agent/voices")
                        ? "active nav-link"
                        : "nav-link text-wrap")
                    }
                    style={
                      location.includes("/smart-agent/agents") ||
                      location.includes("/smart-agent/all-agents") ||
                      location.includes("/smart-agent/auto-reply") ||
                      location.includes("/smart-agent/tools") ||
                      location.includes("/smart-agent/voices")
                        ? {
                            color: "var(--bs-bota-strong-cyan-lime-green)",
                          }
                        : { color: "var(--bs-secondary)" }
                    }
                    aria-expanded={
                      location.includes("/smart-agent/agents") ||
                      location.includes("/smart-agent/all-agents") ||
                      location.includes("/smart-agent/auto-reply") ||
                      location.includes("/smart-agent/tools") ||
                      location.includes("/smart-agent/voices")
                        ? "true"
                        : "false"
                    }
                  >
                    <i className="fas fa-robot" />
                    <span className="ms-3 d-inline-block">
                      {t("common:menu.smart-reply")}
                    </span>
                  </a>

                  <div
                    className={`collapse ${
                      location.includes("/smart-agent/agents") ||
                      location.includes("/smart-agent/all-agents") ||
                      location.includes("/smart-agent/auto-reply") ||
                      location.includes("/smart-agent/tools") ||
                      location.includes("/smart-agent/voices")
                        ? "show"
                        : ""
                    }`}
                    id="collapseSmartReply"
                  >
                    <ul className="nav ps-3">
                      {/* agents */}
                      <li className="nav-item">
                        <Link
                          href="/smart-agent/agents"
                          className={
                            "nav-link text-wrap " +
                            (location === "/smart-agent/agents" ||
                            location.includes("/smart-agent/agents")
                              ? "active nav-link py-3"
                              : "nav-link py-3")
                          }
                          style={
                            location === "/smart-agent/agents" ||
                            location.includes("/smart-agent/agents")
                              ? {
                                  color: "var(--bs-bota-light-blue)",
                                }
                              : {
                                  color: "var(--bs-dark)",
                                }
                          }
                          passHref
                          onClick={showMobilemenu}
                        >
                          <i
                            className={`fab fa-bots`}
                            style={
                              location === "/smart-agent/agents" ||
                              location.includes("/smart-agent/agents")
                                ? {
                                    color:
                                      "var(--bs-bota-strong-cyan-lime-green)",
                                  }
                                : { color: "var(--bs-secondary)" }
                            }
                          />
                          <span className="ms-3 d-inline-block">
                            {t("common:menu.agents")}
                          </span>
                        </Link>
                      </li>

                      {/* smart tools */}
                      <li className="nav-item">
                        <Link
                          href="/smart-agent/tools"
                          className={
                            "nav-link text-wrap " +
                            (location === "/smart-agent/tools" ||
                            location.includes("/smart-agent/tools")
                              ? "active nav-link py-3"
                              : "nav-link py-3")
                          }
                          style={
                            location === "/smart-agent/tools" ||
                            location.includes("/smart-agent/tools")
                              ? {
                                  color: "var(--bs-bota-light-blue)",
                                }
                              : {
                                  color: "var(--bs-dark)",
                                }
                          }
                          passHref
                          onClick={showMobilemenu}
                        >
                          <i
                            className={`fa-solid fa-screwdriver-wrench`}
                            style={
                              location === "/smart-agent/tools" ||
                              location.includes("/smart-agent/tools")
                                ? {
                                    color:
                                      "var(--bs-bota-strong-cyan-lime-green)",
                                  }
                                : { color: "var(--bs-secondary)" }
                            }
                          />
                          <span className="ms-3 d-inline-block">
                            {t("common:menu.smart-tools")}
                          </span>
                        </Link>
                      </li>

                      {/* autoreply */}
                      <li className="nav-item">
                        <Link
                          href="/smart-agent/auto-reply"
                          className={
                            "nav-link text-wrap " +
                            (location === "/smart-agent/auto-reply" ||
                            location.includes("/smart-agent/auto-reply")
                              ? "active nav-link py-3"
                              : "nav-link py-3")
                          }
                          style={
                            location === "/smart-agent/auto-reply" ||
                            location.includes("/smart-agent/auto-reply")
                              ? {
                                  color: "var(--bs-bota-light-blue)",
                                }
                              : {
                                  color: "var(--bs-dark)",
                                }
                          }
                          passHref
                          onClick={showMobilemenu}
                        >
                          <i
                            className={`fas fa-reply-all`}
                            style={
                              location === "/smart-agent/auto-reply" ||
                              location.includes("/smart-agent/auto-reply")
                                ? {
                                    color:
                                      "var(--bs-bota-strong-cyan-lime-green)",
                                  }
                                : { color: "var(--bs-secondary)" }
                            }
                          />
                          <span className="ms-3 d-inline-block">
                            {t("common:menu.auto-reply")}
                          </span>
                        </Link>
                      </li>
                      {/* voice */}
                      <li className="nav-item">
                        <Link
                          href="/smart-agent/voices"
                          className={
                            "nav-link text-wrap " +
                            (location === "/smart-agent/voices" ||
                            location.includes("/smart-agent/voices")
                              ? "active nav-link py-3"
                              : "nav-link py-3")
                          }
                          style={
                            location === "/smart-agent/voices" ||
                            location.includes("/smart-agent/voices")
                              ? {
                                  color: "var(--bs-bota-light-blue)",
                                }
                              : {
                                  color: "var(--bs-dark)",
                                }
                          }
                          passHref
                          onClick={showMobilemenu}
                        >
                          <i
                            className={`fas fa-microphone-lines`}
                            style={
                              location === "/smart-agent/voices" ||
                              location.includes("/smart-agent/voices")
                                ? {
                                    color:
                                      "var(--bs-bota-strong-cyan-lime-green)",
                                  }
                                : { color: "var(--bs-secondary)" }
                            }
                          />
                          <span className="ms-3 d-inline-block">
                            {t("common:menu.voice")}
                          </span>
                        </Link>
                      </li>

                      {/* admin only */}
                      {session?.user?.role === "admin" && (
                        <>
                          <li className="nav-item">
                            <Link
                              href="/smart-agent/all-agents"
                              className={
                                "nav-link text-wrap " +
                                (location === "/smart-agent/all-agents" ||
                                location.includes("/smart-agent/all-agents")
                                  ? "active nav-link py-3"
                                  : "nav-link py-3")
                              }
                              style={
                                location === "/smart-agent/all-agents" ||
                                location.includes("/smart-agent/all-agents")
                                  ? {
                                      color: "var(--bs-bota-light-blue)",
                                    }
                                  : {
                                      color: "var(--bs-dark)",
                                    }
                              }
                              passHref
                              onClick={showMobilemenu}
                            >
                              <i
                                className={`fab fa-bots`}
                                style={
                                  location === "/smart-agent/all-agents" ||
                                  location.includes("/smart-agent/all-agents")
                                    ? {
                                        color:
                                          "var(--bs-bota-strong-cyan-lime-green)",
                                      }
                                    : { color: "var(--bs-secondary)" }
                                }
                              />
                              <span className="ms-3 d-inline-block">
                                {t("common:menu.all-agents")}
                              </span>
                            </Link>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </li>
              )}

              {/* bulk sending */}
              {session?.user?.currentCompany.access_blocks.includes(
                "bulk_sending"
              ) && (
                <li className="nav-item">
                  <a
                    data-bs-toggle="collapse"
                    href="#bulkSending"
                    role="button"
                    aria-controls="bulkSending"
                    className={
                      "nav-link text-wrap " +
                      (location.includes("/company/messages") ||
                      location.includes("/company/campaigns") ||
                      location.includes("/all-campaigns")
                        ? "active nav-link"
                        : "nav-link text-wrap")
                    }
                    style={
                      location.includes("/company/messages") ||
                      location.includes("/company/campaigns") ||
                      location.includes("/all-campaigns")
                        ? {
                            color: "var(--bs-bota-strong-cyan-lime-green)",
                          }
                        : { color: "var(--bs-secondary)" }
                    }
                    aria-expanded={
                      location.includes("/company/messages") ||
                      location.includes("/company/campaigns") ||
                      location.includes("/all-campaigns")
                        ? "true"
                        : "false"
                    }
                  >
                    <i className="fas fa-envelopes-bulk" />
                    <span className="ms-3 d-inline-block">
                      {t("common:menu.bulk-sending")}
                    </span>
                  </a>

                  <div
                    className={`collapse ${
                      location.includes("/company/messages") ||
                      location.includes("/company/campaigns") ||
                      location.includes("/all-campaigns")
                        ? "show"
                        : ""
                    }`}
                    id="bulkSending"
                  >
                    <ul className="nav ps-3">
                      <li className="nav-item">
                        <Link
                          href="/company/messages"
                          className={
                            "nav-link text-wrap " +
                            (location === "/company/messages" ||
                            location.includes("/company/messages")
                              ? "active nav-link py-3"
                              : "nav-link py-3")
                          }
                          style={
                            location === "/company/messages" ||
                            location.includes("/company/messages")
                              ? {
                                  color: "var(--bs-bota-light-blue)",
                                }
                              : {
                                  color: "var(--bs-dark)",
                                }
                          }
                          passHref
                          onClick={showMobilemenu}
                        >
                          <i
                            className={`fas fa-envelope`}
                            style={
                              location === "/company/messages" ||
                              location.includes("/company/messages")
                                ? {
                                    color:
                                      "var(--bs-bota-strong-cyan-lime-green)",
                                  }
                                : { color: "var(--bs-secondary)" }
                            }
                          />
                          <span className="ms-3 d-inline-block">
                            {t("common:menu.messages")}
                          </span>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          href="/company/campaigns"
                          className={
                            "nav-link text-wrap " +
                            (location === "/company/campaigns" ||
                            location.includes("/company/campaigns")
                              ? "active nav-link py-3"
                              : "nav-link py-3")
                          }
                          style={
                            location === "/company/campaigns" ||
                            location.includes("/company/campaigns")
                              ? {
                                  color: "var(--bs-bota-light-blue)",
                                }
                              : {
                                  color: "var(--bs-dark)",
                                }
                          }
                          passHref
                          onClick={showMobilemenu}
                        >
                          <i
                            className={`fas fa-paper-plane`}
                            style={
                              location === "/company/campaigns" ||
                              location.includes("/company/campaigns")
                                ? {
                                    color:
                                      "var(--bs-bota-strong-cyan-lime-green)",
                                  }
                                : { color: "var(--bs-secondary)" }
                            }
                          />
                          <span className="ms-3 d-inline-block">
                            {t("common:menu.my-campaigns")}
                          </span>
                        </Link>
                      </li>

                      {session?.user?.role === "admin" && (
                        <li className="nav-item">
                          <Link
                            href="/all-campaigns"
                            className={
                              "nav-link text-wrap " +
                              (location === "/all-campaigns" ||
                              location.includes("/all-campaigns")
                                ? "active nav-link py-3"
                                : "nav-link py-3")
                            }
                            style={
                              location === "/all-campaigns" ||
                              location.includes("/all-campaigns")
                                ? {
                                    color: "var(--bs-bota-light-blue)",
                                  }
                                : {
                                    color: "var(--bs-dark)",
                                  }
                            }
                            passHref
                            onClick={showMobilemenu}
                          >
                            <i
                              className={`fas fa-paper-plane`}
                              style={
                                location === "/all-campaigns" ||
                                location.includes("/all-campaigns")
                                  ? {
                                      color:
                                        "var(--bs-bota-strong-cyan-lime-green)",
                                    }
                                  : { color: "var(--bs-secondary)" }
                              }
                            />
                            <span className="ms-3 d-inline-block">
                              {t("common:menu.all-campaigns")}
                            </span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              )}

              {/* chatbot */}
              {session?.user?.currentCompany.access_blocks.includes(
                "chatbot"
              ) && (
                <li className="nav-item">
                  <Link
                    href="https://chatbot.smart.botatende.com"
                    target="_blank"
                    className={"nav-link text-wrap nav-link py-3"}
                    passHref
                    onClick={showMobilemenu}
                  >
                    <i className={`fab fa-bots`} />
                    <span className="ms-3 d-inline-block">
                      {t("common:menu.chatbot")}
                    </span>
                  </Link>
                </li>
              )}

              {/* automations  */}
              {session?.user?.currentCompany.access_blocks.includes(
                "automations"
              ) && (
                <li className="nav-item">
                  <a
                    data-bs-toggle="collapse"
                    href="#automations"
                    role="button"
                    aria-controls="automations"
                    className={
                      "nav-link text-wrap " +
                      (location.includes("/company/actions") ||
                      location.includes("/company/webhook-callback") ||
                      location.includes("/admin/webhook-callback") ||
                      location.includes("/company/apis")
                        ? "active nav-link"
                        : "nav-link text-wrap")
                    }
                    style={
                      location.includes("/company/actions") ||
                      location.includes("/company/webhook-callback") ||
                      location.includes("/admin/webhook-callback") ||
                      location.includes("/company/apis")
                        ? {
                            color: "var(--bs-bota-strong-cyan-lime-green)",
                          }
                        : { color: "var(--bs-secondary)" }
                    }
                    aria-expanded={
                      location.includes("/company/actions") ||
                      location.includes("/company/webhook-integrations") ||
                      location.includes("/admin/webhook-callback") ||
                      location.includes("/company/apis")
                        ? "true"
                        : "false"
                    }
                  >
                    <i className="fas fa-robot" />
                    <span className="ms-3 d-inline-block">
                      {t("common:menu.automations")}
                    </span>
                  </a>

                  <div
                    className={`collapse ${
                      location.includes("/company/actions") ||
                      location.includes("/company/webhook-integrations") ||
                      location.includes("/admin/webhook-callback") ||
                      location.includes("/company/apis")
                        ? "show"
                        : ""
                    }`}
                    id="automations"
                  >
                    <ul className="nav ps-3">
                      {session?.user?.role === "admin" && (
                        <li className="nav-item">
                          <Link
                            href="/admin/webhook-callback/templates"
                            className={
                              "nav-link text-wrap " +
                              (location ===
                                "/admin/webhook-callback/templates" ||
                              location.includes(
                                "/admin/webhook-callback/templates"
                              )
                                ? "active nav-link py-3"
                                : "nav-link py-3")
                            }
                            style={
                              location ===
                                "/admin/webhook-callback/templates" ||
                              location.includes(
                                "/admin/webhook-callback/templates"
                              )
                                ? {
                                    color: "var(--bs-bota-light-blue)",
                                  }
                                : {
                                    color: "var(--bs-dark)",
                                  }
                            }
                            passHref
                            onClick={showMobilemenu}
                          >
                            <i
                              className={`fas fa-otter`}
                              style={
                                location ===
                                  "/admin/webhook-callback/templates" ||
                                location.includes(
                                  "/admin/webhook-callback/templates"
                                )
                                  ? {
                                      color:
                                        "var(--bs-bota-strong-cyan-lime-green)",
                                    }
                                  : { color: "var(--bs-secondary)" }
                              }
                            />
                            <span className="ms-3 d-inline-block">
                              {t("common:menu.webhook-templates")}
                            </span>
                          </Link>
                        </li>
                      )}

                      <li className="nav-item">
                        <Link
                          href="/company/webhook-integrations"
                          className={
                            "nav-link text-wrap " +
                            (location === "/company/webhook-integrations" ||
                            location.includes("/company/webhook-integrations")
                              ? "active nav-link py-3"
                              : "nav-link py-3")
                          }
                          style={
                            location === "/company/webhook-integrations" ||
                            location.includes("/company/webhook-integrations")
                              ? {
                                  color: "var(--bs-bota-light-blue)",
                                }
                              : {
                                  color: "var(--bs-dark)",
                                }
                          }
                          passHref
                          onClick={showMobilemenu}
                        >
                          <i
                            className={`fas fa-otter`}
                            style={
                              location === "/company/webhook-integrations" ||
                              location.includes("/company/webhook-integrations")
                                ? {
                                    color:
                                      "var(--bs-bota-strong-cyan-lime-green)",
                                  }
                                : { color: "var(--bs-secondary)" }
                            }
                          />
                          <span className="ms-3 d-inline-block">
                            {t("common:menu.webhook-integrations")}
                          </span>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          href="/company/apis"
                          className={
                            "nav-link text-wrap " +
                            (location === "/company/apis" ||
                            location.includes("/company/apis")
                              ? "active nav-link py-3"
                              : "nav-link py-3")
                          }
                          style={
                            location === "/company/apis" ||
                            location.includes("/company/apis")
                              ? {
                                  color: "var(--bs-bota-light-blue)",
                                }
                              : {
                                  color: "var(--bs-dark)",
                                }
                          }
                          passHref
                          onClick={showMobilemenu}
                        >
                          <i
                            className={`fas fa-tower-cell`}
                            style={
                              location === "/company/apis" ||
                              location.includes("/company/apis")
                                ? {
                                    color:
                                      "var(--bs-bota-strong-cyan-lime-green)",
                                  }
                                : { color: "var(--bs-secondary)" }
                            }
                          />
                          <span className="ms-3 d-inline-block">
                            {t("common:menu.apis")}
                          </span>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          href="/company/actions"
                          className={
                            "nav-link text-wrap " +
                            (location === "/company/actions" ||
                            location.includes("/company/actions")
                              ? "active nav-link py-3"
                              : "nav-link py-3")
                          }
                          style={
                            location === "/company/actions" ||
                            location.includes("/company/actions")
                              ? {
                                  color: "var(--bs-bota-light-blue)",
                                }
                              : {
                                  color: "var(--bs-dark)",
                                }
                          }
                          passHref
                          onClick={showMobilemenu}
                        >
                          <i
                            className={`fas fa-clipboard-list`}
                            style={
                              location === "/company/actions" ||
                              location.includes("/company/actions")
                                ? {
                                    color:
                                      "var(--bs-bota-strong-cyan-lime-green)",
                                  }
                                : { color: "var(--bs-secondary)" }
                            }
                          />
                          <span className="ms-3 d-inline-block">
                            {t("common:menu.actions")}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              )}
            </>
          )}

          {session?.user?.role === "admin" && (
            <>
              {/* agent library */}
              <li className="nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#collapseAgentLibrary"
                  role="button"
                  aria-controls="collapseAgentLibrary"
                  className={
                    "nav-link text-wrap " +
                    (location.includes("/agent-library/agents")
                      ? "active nav-link"
                      : "nav-link text-wrap")
                  }
                  style={
                    location.includes("/agent-library/agents")
                      ? {
                          color: "var(--bs-bota-strong-cyan-lime-green)",
                        }
                      : { color: "var(--bs-secondary)" }
                  }
                  aria-expanded={
                    location.includes("/agent-library/agents")
                      ? "true"
                      : "false"
                  }
                >
                  <i className="fas fa-robot" />
                  <span className="ms-3 d-inline-block">
                    {t("common:menu.agent-library")}
                  </span>
                </a>

                <div
                  className={`collapse ${
                    location.includes("/agent-library/agents") ? "show" : ""
                  }`}
                  id="collapseAgentLibrary"
                >
                  <ul className="nav ps-3">
                    {/* agents */}
                    <li className="nav-item">
                      <Link
                        href="/agent-library/agents"
                        className={
                          "nav-link text-wrap " +
                          (location === "/agent-library/agents" ||
                          location.includes("/agent-library/agents")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/agent-library/agents" ||
                          location.includes("/agent-library/agents")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fab fa-bots`}
                          style={
                            location === "/agent-library/agents" ||
                            location.includes("/agent-library/agents")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.agents")}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* External APIs  */}
              <li className="nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#external"
                  role="button"
                  aria-controls="external"
                  className={
                    "nav-link text-wrap " +
                    (location.includes("/external/partner-management") ||
                    location.includes("/external/models-management") ||
                    location.includes("/external/company-management") ||
                    location.includes("/external/agent-management") ||
                    location.includes("/external/voice-management")
                      ? "active nav-link"
                      : "nav-link text-wrap")
                  }
                  style={
                    location.includes("/external/partner-management") ||
                    location.includes("/external/models-management") ||
                    location.includes("/external/company-management") ||
                    location.includes("/external/agent-management") ||
                    location.includes("/external/voice-management")
                      ? {
                          color: "var(--bs-bota-strong-cyan-lime-green)",
                        }
                      : { color: "var(--bs-secondary)" }
                  }
                  aria-expanded={
                    location.includes("/external/partner-management") ||
                    location.includes("/external/models-management") ||
                    location.includes("/external/company-management") ||
                    location.includes("/external/agent-management") ||
                    location.includes("/external/voice-management")
                      ? "true"
                      : "false"
                  }
                >
                  <i className="fas fa-arrow-up-right-from-square" />
                  <span className="ms-3 d-inline-block">
                    {t("common:menu.bsmart-management")}
                  </span>
                </a>

                <div
                  className={`collapse ${
                    location.includes("/external/partner-management") ||
                    location.includes("/external/models-management") ||
                    location.includes("/external/company-management") ||
                    location.includes("/external/agent-management") ||
                    location.includes("/external/voice-management") ||
                    location.includes("/external/voice-management")
                      ? "show"
                      : ""
                  }`}
                  id="external"
                >
                  <ul className="nav ps-3">
                    <li className="nav-item">
                      <Link
                        href="/external/partner-management"
                        className={
                          "nav-link text-wrap " +
                          (location === "/external/partner-management" ||
                          location.includes("/external/partner-management")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/external/partner-management" ||
                          location.includes("/external/partner-management")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-handshake`}
                          style={
                            location === "/external/partner-management" ||
                            location.includes("/external/partner-management")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.partner-management")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/external/models-management"
                        className={
                          "nav-link text-wrap " +
                          (location === "/external/models-management" ||
                          location.includes("/external/models-management")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/external/models-management" ||
                          location.includes("/external/models-management")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-laptop-code`}
                          style={
                            location === "/integrations/models-management" ||
                            location.includes("/integrations/models-management")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.models-management")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/external/company-management"
                        className={
                          "nav-link text-wrap " +
                          (location === "/external/company-management" ||
                          location.includes("/external/company-management")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/external/company-management" ||
                          location.includes("/external/company-management")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`far fa-building`}
                          style={
                            location === "/external/company-management" ||
                            location.includes("/external/company-management")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.company-management")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/external/agent-management"
                        className={
                          "nav-link text-wrap " +
                          (location === "/external/agent-management" ||
                          location.includes("/external/agent-management")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/external/agent-management" ||
                          location.includes("/external/agent-management")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fab fa-bots`}
                          style={
                            location === "/external/agent-management" ||
                            location.includes("/external/agent-management")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.agent-management")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/external/voice-management"
                        className={
                          "nav-link text-wrap " +
                          (location === "/external/voice-management" ||
                          location.includes("/external/voice-management")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/external/voice-management" ||
                          location.includes("/external/voice-management")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-microphone-lines`}
                          style={
                            location === "/external/voice-management" ||
                            location.includes("/external/voice-management")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.voice-management")}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* settings  */}
              <li className="nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#settings"
                  role="button"
                  aria-controls="settings"
                  className={
                    "nav-link text-wrap " +
                    (location.includes("/admin/whatsapp-channels") ||
                    location.includes("/admin/messages") ||
                    location.includes("/admin/campaigns") ||
                    location.includes("/settings/general") ||
                    location.includes("/settings/system-emails")
                      ? "active nav-link"
                      : "nav-link text-wrap")
                  }
                  style={
                    location.includes("/admin/whatsapp-channels") ||
                    location.includes("/admin/messages") ||
                    location.includes("/admin/campaigns") ||
                    location.includes("/settings/general") ||
                    location.includes("/settings/system-emails")
                      ? {
                          color: "var(--bs-bota-strong-cyan-lime-green)",
                        }
                      : { color: "var(--bs-secondary)" }
                  }
                  aria-expanded={
                    location.includes("/admin/whatsapp-channels") ||
                    location.includes("/admin/messages") ||
                    location.includes("/admin/campaigns") ||
                    location.includes("/settings/general") ||
                    location.includes("/settings/system-emails")
                      ? "true"
                      : "false"
                  }
                >
                  <i className="fas fa-cog" />
                  <span className="ms-3 d-inline-block">
                    {t("common:menu.settings")}
                  </span>
                </a>

                <div
                  className={`collapse ${
                    location.includes("/admin/whatsapp-channels") ||
                    location.includes("/admin/messages") ||
                    location.includes("/admin/campaigns") ||
                    location.includes("/settings/general") ||
                    location.includes("/settings/system-emails")
                      ? "show"
                      : ""
                  }`}
                  id="settings"
                >
                  <ul className="nav ps-3">
                    {/* general */}
                    <li className="nav-item">
                      <Link
                        href="/settings/general"
                        className={
                          "nav-link text-wrap " +
                          (location === "/settings/general" ||
                          location.includes("/settings/general")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/settings/general" ||
                          location.includes("/settings/general")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-toolbox`}
                          style={
                            location === "/settings/general" ||
                            location.includes("/settings/general")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.general")}
                        </span>
                      </Link>
                    </li>

                    {/* system emails */}
                    <li className="nav-item">
                      <Link
                        href="/settings/system-emails"
                        className={
                          "nav-link text-wrap " +
                          (location === "/settings/system-emails" ||
                          location.includes("/settings/system-emails")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/settings/system-emails" ||
                          location.includes("/settings/system-emails")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-envelope-circle-check`}
                          style={
                            location === "/settings/system-emails" ||
                            location.includes("/settings/system-emails")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.system-emails")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/admin/whatsapp-channels"
                        className={
                          "nav-link text-wrap " +
                          (location === "/admin/whatsapp-channels" ||
                          location.includes("/admin/whatsapp-channels")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/admin/whatsapp-channels" ||
                          location.includes("/admin/whatsapp-channels")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fab fa-whatsapp`}
                          style={
                            location === "/admin/whatsapp-channels" ||
                            location.includes("/admin/whatsapp-channels")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.whatsapp")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/admin/messages"
                        className={
                          "nav-link text-wrap " +
                          (location === "/admin/messages" ||
                          location.includes("/admin/messages")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/admin/messages" ||
                          location.includes("/admin/messages")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-envelope`}
                          style={
                            location === "/admin/messages" ||
                            location.includes("/admin/messages")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.messages")}
                        </span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        href="/admin/campaigns"
                        className={
                          "nav-link text-wrap " +
                          (location === "/admin/campaigns" ||
                          location.includes("/admin/campaigns")
                            ? "active nav-link py-3"
                            : "nav-link py-3")
                        }
                        style={
                          location === "/admin/campaigns" ||
                          location.includes("/admin/campaigns")
                            ? {
                                color: "var(--bs-bota-light-blue)",
                              }
                            : {
                                color: "var(--bs-dark)",
                              }
                        }
                        passHref
                        onClick={showMobilemenu}
                      >
                        <i
                          className={`fas fa-paper-plane`}
                          style={
                            location === "/admin/campaigns" ||
                            location.includes("/admin/campaigns")
                              ? {
                                  color:
                                    "var(--bs-bota-strong-cyan-lime-green)",
                                }
                              : { color: "var(--bs-secondary)" }
                          }
                        />
                        <span className="ms-3 d-inline-block">
                          {t("common:menu.system-campaigns")}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </>
          )}

          {/* support tickets */}
          {(session?.user?.role === "admin" ||
            session?.user?.role === "support") && (
            <>
              <li className="nav-item">
                <Link
                  href="/support/tickets"
                  className={
                    "nav-link text-wrap " +
                    (location === "/support/tickets" ||
                    location.includes("/support/tickets")
                      ? "active nav-link py-3"
                      : "nav-link py-3")
                  }
                  style={
                    location === "/support/tickets" ||
                    location.includes("/support/tickets")
                      ? {
                          color: "var(--bs-bota-light-blue)",
                        }
                      : {
                          color: "var(--bs-dark)",
                        }
                  }
                  passHref
                  onClick={showMobilemenu}
                >
                  <i
                    className={`fas fa-handshake-angle`}
                    style={
                      location === "/support/tickets" ||
                      location.includes("/support/tickets")
                        ? {
                            color: "var(--bs-bota-strong-cyan-lime-green)",
                          }
                        : { color: "var(--bs-secondary)" }
                    }
                  />
                  <span className="ms-3 d-inline-block">
                    {t("common:menu.support-requests")}
                  </span>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  href="/support/customers"
                  className={
                    "nav-link text-wrap " +
                    (location === "/support/customers" ||
                    location.includes("/support/customers")
                      ? "active nav-link py-3"
                      : "nav-link py-3")
                  }
                  style={
                    location === "/support/customers" ||
                    location.includes("/support/customers")
                      ? {
                          color: "var(--bs-bota-light-blue)",
                        }
                      : {
                          color: "var(--bs-dark)",
                        }
                  }
                  passHref
                  onClick={showMobilemenu}
                >
                  <i
                    className={`fas fa-users`}
                    style={
                      location === "/support/customers" ||
                      location.includes("/support/customers")
                        ? {
                            color: "var(--bs-bota-strong-cyan-lime-green)",
                          }
                        : { color: "var(--bs-secondary)" }
                    }
                  />
                  <span className="ms-3 d-inline-block">
                    {t("common:menu.customers")}
                  </span>
                </Link>
              </li>
            </>
          )}

          {session?.user?.role === "customer" && (
            <li className="nav-item">
              <Link
                href="/company/support"
                className={
                  "nav-link text-wrap " +
                  (location === "/company/support" ||
                  location.includes("/company/support")
                    ? "active nav-link py-3"
                    : "nav-link py-3")
                }
                style={
                  location === "/company/support" ||
                  location.includes("/company/support")
                    ? {
                        color: "var(--bs-bota-light-blue)",
                      }
                    : {
                        color: "var(--bs-dark)",
                      }
                }
                passHref
                onClick={showMobilemenu}
              >
                <i
                  className={`fas fa-ticket-alt`}
                  style={
                    location === "/company/support" ||
                    location.includes("/company/support")
                      ? {
                          color: "var(--bs-bota-strong-cyan-lime-green)",
                        }
                      : { color: "var(--bs-secondary)" }
                  }
                />
                <span className="ms-3 d-inline-block">
                  {t("common:menu.support-tickets")}
                </span>
              </Link>
            </li>
          )}

          {/* profile menu */}
          <li className="nav-item">
            <Link
              href="/profile"
              className={
                "nav-link text-wrap " +
                (location === "/profile" || location.includes("/profile")
                  ? "active nav-link py-3"
                  : "nav-link py-3")
              }
              style={
                location === "/profile" || location.includes("/profile")
                  ? {
                      color: "var(--bs-bota-light-blue)",
                    }
                  : {
                      color: "var(--bs-dark)",
                    }
              }
              passHref
              onClick={showMobilemenu}
            >
              <i
                className={`fas fa-user-gear`}
                style={
                  location === "/profile" || location.includes("/profile")
                    ? {
                        color: "var(--bs-bota-strong-cyan-lime-green)",
                      }
                    : { color: "var(--bs-secondary)" }
                }
              />
              <span className="ms-3 d-inline-block">
                {t("common:menu.profile")}
              </span>
            </Link>
          </li>

          {/* billing menu */}
          <li className="nav-item">
            <Link
              href="/billing"
              className={
                "nav-link text-wrap " +
                (location === "/billing" || location.includes("/billing")
                  ? "active nav-link py-3"
                  : "nav-link py-3")
              }
              style={
                location === "/billing" || location.includes("/billing")
                  ? {
                      color: "var(--bs-bota-light-blue)",
                    }
                  : {
                      color: "var(--bs-dark)",
                    }
              }
              passHref
              onClick={showMobilemenu}
            >
              <i
                className={`fas fa-file-invoice-dollar`}
                style={
                  location === "/billing" || location.includes("/billing")
                    ? {
                        color: "var(--bs-bota-strong-cyan-lime-green)",
                      }
                    : { color: "var(--bs-secondary)" }
                }
              />
              <span className="ms-3 d-inline-block">
                {t("common:menu.billing")}
              </span>
            </Link>
          </li>

          {/* calendar */}

          <li className="nav-item">
            <Link
              href="/calendar"
              className={
                "nav-link text-wrap " +
                (location === "/calendar" || location.includes("/calendar")
                  ? "active nav-link py-3"
                  : "nav-link py-3")
              }
              style={
                location === "/calendar" || location.includes("/calendar")
                  ? {
                      color: "var(--bs-bota-light-blue)",
                    }
                  : {
                      color: "var(--bs-dark)",
                    }
              }
              passHref
              onClick={showMobilemenu}
            >
              <i
                className={`fas fa-calendar`}
                style={
                  location === "/calendar" || location.includes("/calendar")
                    ? {
                        color: "var(--bs-bota-strong-cyan-lime-green)",
                      }
                    : { color: "var(--bs-secondary)" }
                }
              />
              <span className="ms-3 d-inline-block">
                {t("common:menu.calendar")}
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
